/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ForwardRefExoticComponent } from "react";

//ForwardRefExoticComponent is typed as a function, but they are actually objects, hence why we need this typeguard.
const isForwardRefComponent = (
	c: FC<any> | ForwardRefExoticComponent<any>
): c is ForwardRefExoticComponent<any> => typeof c === "object";

export function assignKnobName<T>(
	component: FC<T> | ForwardRefExoticComponent<T>,
	name?: string
): void {
	const isForwardRef = isForwardRefComponent(component);
	if (isForwardRef && !name)
		throw new Error(
			"Provide the 'name' parameter when using 'assignKnobName' for a component using `forwardRef`!"
		);

	(component as any)["__knobName"] = isForwardRef ? name : component.name;
}
