import React, { ReactNode, Children, ReactElement } from "react";
import {
	DataComponent,
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
} from "../../../types/fancy-base-attributes";
import { InlineText } from "../../text/inline-text/inline-text";
import { SrOnly } from "../../text/sr-only/sr-only";
import { Icon } from "../../visuals/icons/icons";
import { Spinner } from "../../feedback/spinner/spinner";
import { assignKnobName } from "../../../utils/documentation-utils";
import { cn, f2u } from "../../../utils/shorthands";
import {
	isElementComponentOfType,
	isOnlyChildOfType,
	isReactFragment,
} from "../../../utils/react-utils";
import { logError } from "../../../utils/error-utils";
import { useStaticMode, useTranslations } from "../../../context/fancy-context";
import * as scss from "./button.scss";

export type ButtonSize = "small" | "medium" | "large";

export type ButtonStyle =
	| "default"
	| "primary"
	| "secondary"
	| "destructive"
	| "borderless"
	| "ctaDefault"
	| "ctaPrimary"
	| "ctaSecondary";

export type ButtonProps = {
	/** Text and icons to be displayed inside the button. */
	children: ReactNode;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
	/** HTML attribute to define the type of the button - defaults to "button" */
	type?: "submit" | "reset" | "button";
	/** URL to which the link will redirect (Should only be used for links that look like buttons) */
	href?: string;
	/** If set to true the link will be opened in a new tab (Should only be used for links that look like buttons) */
	openNew?: boolean;
	/** Controls the size of the button - defaults to medium */
	size?: ButtonSize;
	/** How should the button look */
	variant?: ButtonStyle;
	/** Can the button be clicked */
	disabled?: boolean;
	/** Should the button show a loading indicator */
	loading?: boolean;
	/** Id applied to the button */
	id?: string;
	/** Id of a form element */
	form?: string;
	/** Defines the button as a toggle button. The value of aria-pressed describes the state of the button */
	"aria-pressed"?: boolean;
	/** Identifies the element (or elements) whose contents or presence are controlled by the current element. */
	"aria-controls"?: string;
	/** Describe what happens if the button is clicked (for icon only buttons) */
	"aria-label"?: string;
	/** ID of an element that describes what happens if the button is clicked (for icon only buttons if aria-label is not set) */
	"aria-labelledby"?: string;
	/** IDs of the elements that describe the button's function */
	"aria-describedby"?: string;
	/** If the button controls a grouping of other elements, the aria-expanded state indicates whether the controlled grouping is currently expanded or collapsed. */
	"aria-expanded"?: boolean;
	/** If true, it indicates an interactive popup element, such as menu or dialog, is present */
	"aria-haspopup"?: "true" | "false" | "menu" | "listbox" | "tree" | "grid" | "dialog";
	/** Indicates whether the element is exposed to an accessibility API. */
	"aria-hidden"?: boolean;
	/** A description for the role of the button */
	"aria-roledescription"?: string;
	/** Styles button as if it's in an active state */
	active?: boolean;
	/** Specifies how button looks in the static mode. Defaults to `disable`, unless `href` is provided in which case it defaults to `show`. */
	inStaticMode?: "disable" | "hide" | "show";
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

const InternalButton = React.forwardRef(
	(props: ButtonProps, ref: React.Ref<unknown>): JSX.Element => {
		const {
			children,
			size = "medium",
			variant = "default",
			className,
			loading,
			type,
			"aria-label": ariaLabel,
			"aria-labelledby": ariaLabelledBy,
			"aria-pressed": ariaPressed,
			"aria-controls": ariaControls,
			"aria-describedby": ariaDescribedby,
			"aria-expanded": ariaExapanded,
			"aria-haspopup": ariaHaspopup,
			"aria-hidden": ariaHidden,
			"aria-roledescription": ariaRoleDescription,
			disabled,
			onBlur,
			onFocus,
			onKeyDown,
			onMouseDown,
			onClick,
			onMouseEnter,
			onMouseLeave,
			href,
			openNew,
			style,
			id,
			tabIndex,
			active,
			inStaticMode = props.href ? "show" : "disable",
			...rest
		} = props;

		const i18n = useTranslations();

		const isStatic = useStaticMode();
		if (isStatic && inStaticMode === "hide") {
			return <></>;
		}

		const nonSrOnlyChildren = Children.toArray(children).filter(
			(x) => !isElementComponentOfType(x, SrOnly)
		);
		const isOnlyFragmentChild =
			nonSrOnlyChildren.length === 1 && isReactFragment(nonSrOnlyChildren[0]);
		const iconOnly = isOnlyChildOfType(
			isOnlyFragmentChild
				? (nonSrOnlyChildren[0] as ReactElement).props?.children
				: nonSrOnlyChildren,
			Icon
		);

		if (iconOnly && !ariaLabel && !ariaLabelledBy && !ariaDescribedby) {
			logError(
				"Button with only an Icon as child should have an aria-label, aria-labelledby, or aria-describedby"
			);
		}

		const isDisabled = disabled || loading || (isStatic && inStaticMode === "disable");

		const sharedAttributes: React.HTMLAttributes<HTMLElement> & DataComponent & DataObserveKey = {
			"data-component": "button",
			"data-observe-key": props["data-observe-key"],
			"aria-label": ariaLabel,
			"aria-expanded": ariaExapanded,
			"aria-controls": ariaControls,
			"aria-labelledby": iconOnly ? ariaDescribedby ?? ariaLabelledBy : ariaLabelledBy,
			"aria-describedby": iconOnly ? undefined : ariaDescribedby,
			"aria-haspopup": ariaHaspopup,
			"aria-hidden": ariaHidden,
			"aria-roledescription": ariaRoleDescription,
			id: id,
			onBlur: onBlur,
			onFocus: onFocus,
			onKeyDown: onKeyDown,
			onMouseDown: onMouseDown,
			onClick: onClick,
			onMouseEnter: onMouseEnter,
			onMouseLeave: onMouseLeave,
			className: cn(
				scss.btn,
				variant === "default" ? scss["btnDefault"] : scss[variant],
				scss[size],
				loading && scss.loading,
				iconOnly && scss.iconOnly,
				className,
				isDisabled && scss.disabled,
				active && scss.active,
				"fancy-Button"
			),
			style: style,
		};

		const innerNodes = (
			<>
				{React.Children.map(children, (c) =>
					typeof c === "string" || typeof c === "number" ? (
						<InlineText
							lineHeight="multi-line"
							tone={
								isDisabled
									? "subtle"
									: neutralLightVariants.includes(variant)
									? "neutralLight"
									: undefined
							}
							size={f2u(size === "small") && "xSmall"}
							emphasis={f2u(variant.startsWith("cta")) && "strong"} //this is perhaps a bit of a fragile check, note if renaming button styles
						>
							{c}
						</InlineText>
					) : (
						// most likely an icon only button
						c
					)
				)}
				{loading !== undefined && <SrOnly status>{loading && `${i18n.loading}...`}</SrOnly>}
				{loading && (
					<>
						<div className={scss.loadingSpinner}>
							<Spinner variant="dark" presentational />
						</div>
						<div style={{ margin: 0 }} />
					</>
				)}
			</>
		);

		if (href) {
			return (
				<a
					href={f2u(!isDisabled && href)}
					aria-disabled={f2u(isDisabled && true)}
					tabIndex={f2u(isDisabled && -1)}
					target={f2u(openNew && "_blank")}
					rel={f2u(openNew && "noopener")}
					ref={ref as React.Ref<HTMLAnchorElement>}
					{...sharedAttributes}
					{...rest}
				>
					{openNew && !ariaLabel && (
						<SrOnly>{`${i18n.linkButtonOpenNewAriaLabel}${iconOnly ? `: ${href}` : ""}`}</SrOnly>
					)}
					{innerNodes}
				</a>
			);
		}

		if (openNew) {
			logError(
				"The 'openNew' prop will be ignored since it is only valid for links that look like a button."
			);
		}

		return (
			<button
				type={type || "button"}
				onClick={onClick}
				ref={ref as React.Ref<HTMLButtonElement>}
				aria-pressed={ariaPressed}
				aria-haspopup={ariaHaspopup}
				disabled={isDisabled}
				tabIndex={tabIndex}
				{...sharedAttributes}
				{...rest}
			>
				{innerNodes}
			</button>
		);
	}
);

assignKnobName(InternalButton, "InternalButton");
InternalButton.displayName = "Button";

const neutralLightVariants: ButtonStyle[] = [
	"primary",
	"secondary",
	"destructive",
	"ctaPrimary",
	"ctaSecondary",
];

type GroupProps = {
	/** Must be Button components. */
	children: ReactNode;
	/** A descriptive title for the button group */
	"aria-label"?: string;
} & DataObserveKey &
	VisualComponent;

function Group(props: GroupProps): JSX.Element {
	const { children, className, style, "aria-label": ariaLabel } = props;
	return (
		<div
			data-observe-key={props["data-observe-key"]}
			style={style}
			className={cn(className, scss.buttonGroup)}
			role="group"
			aria-label={ariaLabel}
		>
			{children}
		</div>
	);
}

export const Button = Object.assign(InternalButton, { Group: Group });
