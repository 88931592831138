import React from "react";
import { Link as GatsbyLink } from "gatsby";

import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { ColorRed, ColorStatusPositiveDark } from "../../../design-tokens/variables";
import { TextContainer, Paragraph, Ul, Content } from "../../..";
import { InlineText } from "../../text/inline-text/inline-text";
import { Button } from "../../actions-and-controls/button/button";
import { Icon } from "./icons";
import {
	IconEdit,
	IconDelete,
	IconSettings,
	IconChevron,
	IconClose,
	IconCaret,
	IconArrow,
	IconTrend,
	IconPotentialIssue,
} from "./icons-list";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Icon",
	notepad: "https://hackmd.io/8j1qO-W8RWK-HK5nteVmfA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Icon"
			subTitle="Use this component to clarify the meaning of UI elements and improve scannability. Icons are intended to visually communicate actions, status, content, or feedback."
		/>
		<ContentSection>
			<TextContainer article>
				<ImportExample component={Icon} />
				<InlineMessage variant="warning">
					<Paragraph>
						Looking for a specific icon? Visit the{" "}
						<GatsbyLink to="/lib/components/Visuals/Icons Overview">icons overview</GatsbyLink> for
						a complete overview of all available icons.
					</Paragraph>
				</InlineMessage>
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					The Icon component features a few properties for adjusting its appearance. The following
					sections showcase these properties and explain when and how to use them.
				</Paragraph>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					To use the Icon component, first find the name of the icon you need on the{" "}
					<GatsbyLink to="/lib/components/Visuals/Icons Overview">icons overview</GatsbyLink> page.
					Then, wrap it in <Code>Icon</Code> tags, as shown in the example below.
				</Paragraph>
				<Example fn={defaultExample} />
				<Header.H3>Size</Header.H3>
				<Paragraph>
					Use the <Code>size</Code> property to adjust an icon’s size. The default size is{" "}
					<Code>large</Code>. Choose a size based on available space, how important it is for the
					user to notice the icon, and the size of the icon's label. You can also use the{" "}
					<Code>responsive</Code> size if you need the icon to scale along with the font size of the
					parent element.
				</Paragraph>
				<Example fn={sizeExample} />
				<Header.H3>Rotation</Header.H3>
				<Paragraph>
					Use the <Code>rotation</Code> property to rotate an icon either <Code>0</Code>,{" "}
					<Code>90</Code>, <Code>180</Code>, or <Code>270</Code> degrees. This property removes the
					need for having up to four different versions of certain directional icons, such as
					chevrons and carets. Also, when the <Code>rotation</Code> property changes, the icon
					animates to the new value. This effect can function as visual feedback when the Icon is
					part of an interactive UI element.
				</Paragraph>
				<Example fn={rotationExample} />
				<Header.H3>Fill</Header.H3>
				<Paragraph>
					Use the <Code>fill</Code> property to change the color of an icon. The <Code>fill</Code>{" "}
					property’s default value is <Code>currentColor</Code>. This means it inherits its value
					from the existing <Code>color</Code> value. In other words, an icon will by default have
					the same color as surrounding text.
				</Paragraph>
				<Paragraph>
					Make sure to only use colors that are part of our{" "}
					<GatsbyLink to="/design-tokens">design tokens</GatsbyLink>. This means you have to import
					the color in question, as shown in the example below. Omit the comment slashes in your
					code.
				</Paragraph>
				<Example fn={fillExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Icon}
					initialProps={{ children: <IconChevron /> }}
					exampleValues={{
						children: {
							IconChevron: <IconChevron />,
							IconCaret: <IconCaret />,
							IconArrow: <IconArrow />,
						},
					}}
				/>

				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul
						items={[
							"Use icons to clarify the meaning of UI elements and improve scannability.",
							"Use the same icon to represent the same concept. For example, use a magnifying glass icon should always represent search. It shouldn’t also be used to represent zoom.",
							"Use the same icon for variations of the same concept. For example, always use the same cog icon for anything related to settings. Don’t create different cog icons for different setting types (user, site, account, etc.).",
							"In general, always pair an icon with a visible label. Only omit a visible label when there is a lack of space or the icon is repeated many times on a page.",
							<>
								Use icons consistently. For example, when using a{" "}
								<GatsbyLink to="/lib/components/Actions and controls/Button#button-group">
									button group
								</GatsbyLink>
								, either provide all or none of its buttons with an icon.
							</>,
							"Only use universally recognized icons, especially when omitting a visible label. Examples include a trash can for “delete”, a pencil for “edit”, and a house for “home”.",
							"Only use internationally recognized icons that are not tied to a specific culture or context.",
							"In general, place icons to the left of visible labels. Except when the icons signal directionality, then place them to the right.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							"You purely want to add visual interest to the UI. Icons are meant to carry meaning and help users achieve their goals.",
							"You’re not sure an icon is necessary. Icon overuse leads to cluttered interfaces, so it’s best to err on the side of caution.",
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<Paragraph>
					Ensure a contrast ratio of at least 3:1 between the color of an <Code>Icon</Code> and its
					background. Other than that, accessible use of the <Code>Icon</Code> component depends
					mainly on whether the icon is part of an interactive UI element and has a visible label.
					What to do in each scenario is explained below.
				</Paragraph>
				<Header.H4>Interactive icon with visible label</Header.H4>
				<InlineMessage variant="accessibility">
					<Paragraph>
						No action is required. The label describes the interactive UI element’s purpose to both
						sighted users and screen reader users.
					</Paragraph>
					<Header.H5>Example</Header.H5>
					<Button onClick={() => console.log("clicked")}>
						<Icon>
							<IconEdit />
						</Icon>
						Edit item
					</Button>
				</InlineMessage>
				<Header.H4>Interactive icon without visible label</Header.H4>
				<InlineMessage variant="accessibility">
					<Paragraph>
						Only provide interactive UI elements with icons instead of visible labels when space is
						limited or the UI element is repeated many times on a page. Also, the icon should be
						universally known, such as a trash can for deleting items.
					</Paragraph>
					<Paragraph>
						If those requirements are met, wrap the interactive UI element in a <Code>Tooltip</Code>{" "}
						component. Consult the <Code>Tooltip</Code> component's{" "}
						<GatsbyLink to="/lab/components/Overlay/Tooltip#interactive">documentation</GatsbyLink>{" "}
						for more information.
					</Paragraph>
					<Header.H5>Example</Header.H5>
					<Button onClick={() => console.log("clicked")}>
						<Icon>
							<IconDelete />
						</Icon>
					</Button>
				</InlineMessage>
				<Header.H4>Static icon with visible label</Header.H4>
				<InlineMessage variant="accessibility">
					<Header.H5>Icon's message conveyed by visible label</Header.H5>
					<Paragraph>
						No action is required. The message will not be repeated to screen reader users, since
						the icon is hidden from assistive technologoy.
					</Paragraph>
					<Header.H6>Example</Header.H6>
					<Content padding="none" alignItems="center">
						<Icon size="medium" rotation="180" fill={ColorStatusPositiveDark}>
							<IconTrend />
						</Icon>
						<InlineText emphasis="medium">+50%</InlineText>
					</Content>
					<Header.H5>Icon's message not conveyed by visible label</Header.H5>
					<Paragraph>
						Consult our accessibility experts through the{" "}
						<a href="https://siteimprove.slack.com/archives/C02B3J1LL6S">a11y_fancy</a> channel.
						They will advise on whether to use a{" "}
						<GatsbyLink to="/lab/components/Overlay/Tooltip">Tooltip</GatsbyLink> or{" "}
						<GatsbyLink to="/lib/components/Text/Screen reader only (SrOnly)">
							SrOnly (Screen reader Only)
						</GatsbyLink>{" "}
						component.
					</Paragraph>
					<Header.H6>Example</Header.H6>
					<Content padding="none" alignItems="center">
						<Icon size="medium" rotation="180" fill={ColorStatusPositiveDark}>
							<IconTrend />
						</Icon>
						<InlineText emphasis="medium">50%</InlineText>
					</Content>
				</InlineMessage>
				<Header.H4>Static icon without visible label</Header.H4>
				<InlineMessage variant="accessibility">
					<Header.H5>Icon is decorative</Header.H5>
					<Paragraph>
						This practice is strongly discouraged. Icons should always convey meaning. Instead,
						consider using an illustration.
					</Paragraph>
					<Header.H5>Icon’s message essential to task completion</Header.H5>
					<Paragraph>
						This practice is strongly discouraged. If an icon's message helps users complete their
						primary tasks, it should accompany the icon as a visible label.
					</Paragraph>
					<Header.H5>Icon’s message non-essential to task completion</Header.H5>
					<Paragraph>
						It's recommend to show an icon's message next to the icon as a visible label. Even if
						the message doesn't contain information users need to complete their primary tasks.
					</Paragraph>
					<Paragraph>
						However, if the label clutters the UI and distracts users from their primary tasks, it's
						acceptable to put it in a{" "}
						<GatsbyLink to="/lab/components/Overlay/Tooltip">Tooltip</GatsbyLink>. Consult the{" "}
						<Code>Tooltip</Code> component's{" "}
						<GatsbyLink to="/lab/components/Overlay/Tooltip">documentation</GatsbyLink> for more
						information.
					</Paragraph>
					<Header.H5>Example</Header.H5>
					<Icon>
						<IconPotentialIssue />
					</Icon>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Paragraph>There are no writing guidelines for this component.</Paragraph>
				</InlineMessage>
				<Header.H2>Notable changes</Header.H2>
				<Header.H3>Version 0.0.x</Header.H3>
				<Ul
					items={[
						<>
							<Code>IconRobot</Code> has been renamed to <Code>IconAssistiveTechnology</Code>.
						</>,
						<>
							<Code>IconPollutingFactory</Code> has been renamed to{" "}
							<Code>IconIndustryBenchmark</Code>.
						</>,
						<>
							<Code>IconCornerArrow</Code> has been renamed to <Code>IconArrowDownLeft</Code>.
						</>,
						<>
							The (unused) <Code>flip</Code> property was removed, which function was to flip icons
							on the x-axis.
						</>,
					]}
				/>
			</TextContainer>
		</ContentSection>
	</>
);

const defaultExample = () => (
	<Icon>
		<IconSettings />
	</Icon>
);

const sizeExample = () => (
	<>
		<Icon size="small">
			<IconClose />
		</Icon>
		<Icon size="medium">
			<IconClose />
		</Icon>
		<Icon size="large">
			<IconClose />
		</Icon>
		<div style={{ display: "inline-flex", fontSize: "2rem" }}>
			<Icon size="responsive">
				<IconClose />
			</Icon>
		</div>
	</>
);

const rotationExample = () => (
	<>
		<Icon rotation="0">
			<IconChevron />
		</Icon>
		<Icon rotation="90">
			<IconChevron />
		</Icon>
		<Icon rotation="180">
			<IconChevron />
		</Icon>
		<Icon rotation="270">
			<IconChevron />
		</Icon>
	</>
);

const fillExample = () => (
	<Icon fill={ColorRed}>
		<IconChevron />
	</Icon>
);
